/* eslint-disable react/prop-types */
// TODO: Have not figured out how to get proper type definitions for Algolia

import React from 'react';
import LocalizedLink from '../LocalizedLink';
import Product from '../../models/Product';
import Price from '../Price/Price';
import DiscountPercentage from '../Price/DiscountPercentage';
import Image from '../Image';
import { Sizes } from '../../constants/Image';

const SearchHit = ({ hit, close, sendEvent }) => {
  const product = new Product(hit);

  const handleClick = () => {
    sendEvent('click', hit, 'Hit Clicked');
    close();
  };

  return (
    <LocalizedLink
      to={`/toode/${product.slug}`}
      className="p-2 block"
      onClick={handleClick}
    >
      <div className="relative">
        <DiscountPercentage
          prices={product.prices(true)}
          productNew={product.isNew}
          componentClassname="absolute left-0 !text-xs"
          containerClassname="items-start pt-4 pl-3"
        />
        <Image
          className={'rounded bg-rgrey-lightlight object-cover'}
          defaultSize={Sizes.W800}
          image={product.cover}
          allSizes={[Sizes.W300, Sizes.W540, Sizes.W800]}
        />
        <span className="text-s pt-1">{hit.title}</span>
        <div className="text-s pt-1">
          <span className="capitalize">{hit.brand}</span>
          <span> {hit.gridTitle}</span>
        </div>

        <div className="pt-1">
          <Price
            prices={product.prices(true)}
            productNew={product.isNew}
            discountPriceClassname="text-black mr-2"
          />
        </div>
      </div>
    </LocalizedLink>
  );
};

export default SearchHit;
