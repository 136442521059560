import React, { useEffect, useState } from 'react';
import { Sizes } from '../../constants/Image';
import imgPlaceholder from '../../images/imgPlaceholder.svg';
import mobilePlaceholder from '../../images/homeMobileBannerPlaceholder.svg';
import desktopPlaceholder from '../../images/homeDesktopBannerPlaceholder.svg';

export type ImageData = {
  id?: number;
  urls: { [key: string]: string };
};

interface Props {
  className?: string;
  defaultSize: Sizes;
  image: ImageData;
  allSizes?: Sizes[];
  sizes?: string[];
  width?: string;
  height?: string;
  alt?: string;
  placeholder?: 'img' | 'mobile' | 'desktop';
}

const generateSrcSet = (image, allSizes: Sizes[]) => {
  let srcSetImages = [];

  if (image.urls) {
    srcSetImages = allSizes
      .map((size) => {
        if (image.urls['W' + size]) {
          return `${image.urls['W' + size]} ${size}w`;
        }
        return null;
      })
      .filter((size) => size !== null);
  }

  return srcSetImages;
};

const Image: React.FC<Props> = ({
  image,
  defaultSize,
  className = '',
  allSizes = [],
  sizes = [],
  width,
  height,
  alt,
  placeholder = 'img', // default
}) => {
  const [currentSrcSet, setCurrentSrcSet] = useState([]);
  const [loadFailed, setLoadFailed] = useState(false);

  useEffect(() => {
    if (image) {
      const newSrcSet = generateSrcSet(image, allSizes);
      setCurrentSrcSet(newSrcSet);
    }
  }, [image]);

  let selectedPlaceholder;
  if (placeholder === 'mobile') {
    selectedPlaceholder = mobilePlaceholder;
  } else if (placeholder === 'desktop') {
    selectedPlaceholder = desktopPlaceholder;
  } else {
    selectedPlaceholder = imgPlaceholder;
  }

  if (image) {
    let src = undefined;

    if (image.urls && image.urls['W' + defaultSize]) {
      src = image.urls['W' + defaultSize];
    }

    return (
      <img
        className={className}
        src={loadFailed ? selectedPlaceholder : src}
        srcSet={loadFailed ? undefined : currentSrcSet.join()}
        sizes={sizes.join()}
        width={width}
        height={height}
        alt={alt}
        onError={() => setLoadFailed(true)}
      />
    );
  }

  return (
    <img
      className={'rounded bg-rgrey-lightlight object-cover h-full w-full'}
      src={selectedPlaceholder}
      alt={alt}
    />
  );
};

export default Image;
