import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Banner } from './SingleBanner2';
import BannerDesign2 from './BannerDesign2';

type Props = {
  banners: [Banner];
  size: string;
};

const BannerCarousel2 = ({ banners, size }: Props) => {
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination]}
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{
        delay: 7000,
        disableOnInteraction: false,
      }}
      speed={1000}
      loop={true}
      pagination={{ clickable: true }}
      navigation={true}
      effect={'fade'}
    >
      {banners.map((banner) => (
        <SwiperSlide key={banner.slug}>
          <BannerDesign2 banner={banner} size={size} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BannerCarousel2;
