import React from 'react';
import { gql, useQuery } from '@apollo/client';
import mobilePlaceholder from '../../images/homeMobileBannerPlaceholder.svg';
import desktopPlaceholder from '../../images/homeDesktopBannerPlaceholder.svg';
import BannerDesign2 from './BannerDesign2';
import BannerCarousel2 from './BannerCarousel2';

const GET_BANNER = gql`
  query Banners($slot: String) {
    banners(slot: $slot) {
      slug
      bannerType
      image {
        urls {
          W435
          W540
          W890
          W1600
        }
      }
      buttons {
        buttonType
        title
        url
        buttonGroup
        brand {
          logoUrl
        }
      }
      title
      content
      hasDarkText
    }
  }
`;

export type Banner = {
  slug: string;
  bannerType: string;
  buttons: {
    buttonType: string;
    title: string;
    url: string;
    buttonGroup: string;
    brand: {
      logoUrl: string;
    };
  }[];
  image: {
    urls: {
      W435: string;
      W540: string;
      W890: string;
      W1600: string;
    };
  };
  title: string;
  content: string;
  hasDarkText: boolean;
};

type Props = {
  slot: string;
  size: string;
};

const SingleBanner2 = ({ slot, size }: Props) => {
  const { data, loading, error } = useQuery(GET_BANNER, {
    variables: { slot },
  });

  if (loading || error || !data.banners?.length) {
    if (size === 'mobile') {
      return (
        <img
          src={mobilePlaceholder}
          width="794"
          height="640"
          className="rounded-lg bg-rgrey-lightlight object-cover w-full h-full"
          alt="Rademar"
        />
      );
    } else {
      return (
        <img
          src={desktopPlaceholder}
          width="1800"
          height="640"
          className="rounded-lg bg-rgrey-lightlight object-cover h-full w-full"
          alt="Rademar"
        />
      );
    }
  }

  if (data.banners.length > 1) {
    return <BannerCarousel2 banners={data.banners} size={size} />;
  }

  return <BannerDesign2 banner={data.banners[0]} size={size} />;
};

export default SingleBanner2;
