import React from 'react';
import SingleBanner2 from './SingleBanner2';

const BannersLayout2 = () => {
  return (
    <div>
      <div className="w-full hidden md:block">
        <SingleBanner2 slot="home-main" size="desktop" />
      </div>
      <div className="w-full md:hidden block">
        <SingleBanner2 slot="home-main-mobile" size="mobile" />
      </div>
    </div>
  );
};

export default BannersLayout2;
