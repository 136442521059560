import React from 'react';
import HomeBlock from './HomeBlock';
import BlogBlock from './BlogBlock';
import BannersLayout from '../HomeBanners/BannersLayout';
import BannersLayout2 from '../HomeBanners/BannersLayout2';
import { isProd } from '../../services/Env';

export default class Home extends React.Component {
  public render() {
    return (
      <section className="home">
        <>
          {isProd() ? <BannersLayout /> : <BannersLayout2 />}
          <HomeBlock />
          <BlogBlock limit={2} />
        </>
      </section>
    );
  }
}
